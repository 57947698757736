<template>
  <header >
    <!-- <div class="header-logo">

    </div>
    <div class="header__menu">
      <router-link :to="{ name: this.$routeName.HOME      }">Главная</router-link>
      <router-link :to="{ name: this.$routeName.ABOUT     }">О нас</router-link>
      <router-link :to="{ name: this.$routeName.CONTACT   }">Контакты</router-link>
      <router-link :to="{ name: this.$routeName.PRELOADER }">Прелоадер</router-link>
      <router-link :to="{ name: this.$routeName.NOT_FOUND }">404</router-link>
    </div> -->
  </header>
</template>

<script>

export default {
  name: "Header",
};
</script>

<style lang="scss" scoped>
.header{
  height: 80px;
  width: 100%;
  background: #2C3242;
  color: #D3D3D3;
  a {
    color: #D3D3D3;
    margin-left: 5px;
  }
}
</style>
