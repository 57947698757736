<template>
  <footer></footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped lang="scss">
.footer {
  height: 80px;
  width: 100%;
  background: #2C3242;
  color: #D3D3D3;
}
</style>
